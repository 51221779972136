<template>
  <div>
      error
  </div>
</template>

<script>

export default {
  data: () => ({
  })
}
</script>
